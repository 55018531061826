import React, { useState, useEffect, Fragment } from 'react';
import { SetStateType } from '../../types/util.types';
import Actions from '../Actions';
import Button from '../Button';
import { CbRadio } from '../Inputs';
import * as S from './dropdown-component';
import { DropdownCbOption } from '../../types/util.types';

interface Props {
  label?: string;
  options?: DropdownCbOption[];
  values?: any;
  setValues?: SetStateType<never[]> | any;
  checkbox?: boolean;
  labelColor?: string;
  disabled?: boolean;
  icon?: any;
  small?: boolean;
  checkingDisabled?: boolean;
  singleCheck?: boolean;
}

const Dropdown = ({
  label,
  options,
  values,
  setValues,
  checkbox,
  checkingDisabled,
  singleCheck,
  ...rest
}: Props) => {
  const { icon } = rest;
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  const [state, setState] = useState<any>([]);

  useEffect(() => {
    setState(values);
  }, [values]);

  const handleChange = (
    name: string,
    checked: boolean,
    id: string | number
  ) => {
    if (singleCheck) {
      setState(state[0]?.id === id ? [] : [{ id, name }]);
      return;
    }
    if (!checked) {
      setState([...state, { id, name }]);
    } else {
      setState(
        state.filter((item: any) => {
          return item.id !== id;
        })
      );
    }
  };

  const hasValue = values?.length && values.length > 0 ? true : false;

  const handleOptions = (item: any) => {
    setValues([item]);
    close();
  };
  return (
    <S.wrapper open={open} {...rest}>
      <Actions
        listView={false}
        toggle={toggle}
        open={open}
        close={close}
        dropdown
        checkbox={checkbox}
      >
        {checkbox ? (
          <>
            {options?.map((item: any) => {
              return (
                <Fragment key={item.id}>
                  <CbRadio
                    disabled={checkingDisabled}
                    name={item.name}
                    small
                    onChange={() => {
                      handleChange(
                        item.name,
                        state.find((el: any) => el.id === item.id),
                        item.id
                      );
                    }}
                    checked={state.find((el: any) => el.id === item.id)}
                  >
                    {item.name}
                  </CbRadio>
                </Fragment>
              );
            })}

            {!checkingDisabled && (
              <Button
                secondary
                onClick={() => {
                  setValues(state);
                  close();
                }}
              >
                Confirm
              </Button>
            )}
          </>
        ) : options === undefined || options?.length < 1 ? (
          <S.noOptions>No options</S.noOptions>
        ) : (
          options?.map((item: any) => {
            return (
              <S.options
                key={item.id}
                onClick={() => {
                  handleOptions(item);
                }}
              >
                {item.name}
              </S.options>
            );
          })
        )}
      </Actions>
      {label && (
        <S.label hasValue={hasValue} open={open} {...rest}>
          {label}
        </S.label>
      )}
      {icon && <S.iconWrapper>{icon}</S.iconWrapper>}
      <S.items {...rest}>
        {values?.map((item: any, index: number) => {
          return (
            <Fragment key={item.id}>
              {item.name} {index < values.length - 1 && ',\u00A0'}
            </Fragment>
          );
        })}
      </S.items>
    </S.wrapper>
  );
};

export default Dropdown;
