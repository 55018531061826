import React from 'react';
import Dropdown from '../../../components/Dropdown';
import { DropdownCbOption, SetStateType } from '../../../types/util.types';
import * as S from './discipline-field';
import useGlobal from '../../../store';

interface Props {
  selected: DropdownCbOption[];
  onAdd: SetStateType<DropdownCbOption[]>;
  checkingDisabled?: boolean;
  details?: boolean;
}

const DisciplineField = ({ selected, onAdd, checkingDisabled }: Props) => {
  const [disciplines] = useGlobal((state) => state.services.disciplines);

  return (
    <S.wrapper>
      <Dropdown
        checkbox
        labelColor="#0A0A33"
        label="Discipline"
        options={disciplines}
        values={selected}
        setValues={onAdd}
        checkingDisabled={checkingDisabled}
        singleCheck
      />
    </S.wrapper>
  );
};

export default DisciplineField;
