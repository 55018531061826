import React, { useState } from 'react';
import { format } from 'date-fns';
import { InviteType } from '../../../store/clients/clients.types';
import Button from '../../../components/Button';
import * as S from './invite-item';
import accepted from '../../../images/accepted.svg';
import plane from '../../../images/plane-red.svg';
import warning from '../../../images/warning-orange.svg';
import Modal from '../../../components/Modal';
import paperPlane from '../../../images/paper-plane.svg';
import useGlobal from '../../../store';
import { resendInvitation } from '../../../store/members/members.actions';
import acceptedWhite from '../../../images/accepted-white.svg';
import { toastUtil } from '../../../utils/toast.util';
import { ReactSVG } from 'react-svg';

interface Props {
  invite: InviteType;
}

const status = {
  ACCEPTED: {
    label: 'Accepted',
    icon: accepted
  },
  NOT_CONFIRMED: {
    label: 'No confirmation',
    icon: plane
  },
  EXPIRED: {
    label: 'Expired',
    icon: warning
  }
};

const InviteItem = ({
  invite: { sentDate, state, client, company, project, invitationEmailId }
}: Props) => {
  const [userName] = useGlobal((state) => state.auth.currentUser?.firstName);
  const [isResended, setIsResended] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!open);
  const close = () => {
    if (open) setOpen(false);
  };

  const resendInv = async () => {
    try {
      await resendInvitation(invitationEmailId);
      setIsResended(true);
      close();
      toastUtil(
        'success',
        `Great! Invitation has been successfully sent to ${client.firstName} ${client.lastName}`,
        'extra_small'
      );
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <S.wrapper isResended={isResended}>
      <S.header>
        <span>
          <img src={status[state].icon} alt="" /> {status[state].label}
        </span>
        {format(new Date(sentDate.split(' ')[0]), 'dd.MM.yyyy')}
      </S.header>
      <S.body>
        {client.firstName} {client.lastName} - {company.name}
      </S.body>
      <S.footer resent={isResended}>
        {project?.name && <p>{project.name}</p>}
        {state === 'EXPIRED' && (
          isResended ?
            (
              <span>
                <ReactSVG src={acceptedWhite} />
                Resent
              </span>
            ) : (
              <Button
                small
                onClick={isResended ? null : toggle}
                secondary={isResended}
              >
                Resend
              </Button>
            )
        )}
      </S.footer>
      <Modal isOpen={open} onDismiss={close}>
        <S.modalWrapper>
          <S.title>
            <ReactSVG src={paperPlane} />
            Hi {userName}, do you want to resend invitation to this client?
          </S.title>
          <S.modalContent>
            <S.modalItem>
              <p>Company Name</p>
              <p>{company?.name}</p>
            </S.modalItem>
            {project && (
              <S.modalItem>
                <p>Project Name</p>
                <p>{project?.name}</p>
              </S.modalItem>
            )}
            <S.modalItem>
              <div>
                <p>Client</p>
                <p>
                  {client?.firstName} {client?.lastName}
                </p>
              </div>
              <div>
                <p>Email address</p>
                <p>{client?.email}</p>
              </div>
            </S.modalItem>
          </S.modalContent>
          <S.buttonContainer>
            <Button onClick={close} secondary>
              No, next time
            </Button>
            <Button onClick={resendInv}>Yes, send invitation</Button>
          </S.buttonContainer>
        </S.modalWrapper>
      </Modal>
    </S.wrapper>
  );
};

export default InviteItem;
