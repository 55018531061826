import React, { useState, Fragment, useCallback } from 'react';
import { AssociatedValueOption, AVDropdownConfig, SetStateType } from '../../types/util.types';
import Actions from '../Actions';
import * as S from './associated-value-dropdown';
import AVDropdownItemList from './AVDropdownItemList/AVDropdownItemList';

interface Props {
  options?: AssociatedValueOption[];
  setOptions?: SetStateType<never[]> | any;
  values?: AssociatedValueOption[];
  setValues?: SetStateType<never[]> | any;
  initials?: AssociatedValueOption[];
  checkbox?: boolean;
  singleCheck?: boolean;
  label?: string;
  labelColor?: string;
  icon?: any;
  unit?: string;
  disabled?: boolean,
  config?: AVDropdownConfig,
  forceOpen?: boolean | any,
  setForceOpen?: SetStateType<never[]> | any;
  preselect?: (string | number)[];
  isInvalid: (option: AssociatedValueOption) => boolean,
  validate?: () => boolean;
}

const AssociatedValueDropdown = ({
  label,
  options = [],
  setOptions,
  values,
  setValues,
  initials,
  checkbox,
  singleCheck,
  unit,
  config = { numeric: false },
  preselect = [],
  forceOpen,
  setForceOpen = () => false,
  isInvalid = () => false,
  validate = () => true,
  disabled,
  ...rest
}: Props) => {
  const { icon } = rest;
  const isEmpty = options === undefined || options?.length < 1;
  const hasValue = values?.length && values.length > 0 ? true : false;

  const [open, setOpen] = useState<boolean>(!!forceOpen || false);
  const [state, setState] = useState<(string | number)[]>(preselect);

  const toggle = () => setOpen(!open);

  const close = () => {
    open && setOpen(false);
    forceOpen && setForceOpen(false);
  };

  const isChecked = (item: AssociatedValueOption): boolean => {
    return !!state.find((el: any) => el === item.id);
  };

  const getValues = () => { return options.filter(e => isChecked(e)) }

  const handleCheck = (
    option: AssociatedValueOption,
    checked: boolean
  ) => {
    if (!!singleCheck) {
      setState(state[0] === option.id ? [] : [option.id]);
      return;
    } else if (checked) {
      setState([...state, option.id]);
    } else {
      setState(
        state.filter((item: any) => {
          return item !== option.id;
        })
      );
    }
  };

  const handleEdit = useCallback((option: AssociatedValueOption, value: string | number) => {
    setOptions(options.map(e => {
      if (e.id === option.id)
        return { ...e, value: config?.numeric ? +(Number(value).toFixed(config?.step || 0)) : value };
      return e;
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <S.avWrapper {...rest} open={open || forceOpen} disabled={disabled}>
      <Actions
        listView={false}
        toggle={toggle}
        open={open || forceOpen}
        close={close}
        dropdown
        associatedValue={true}
        checkbox={checkbox}
      >
        {
          isEmpty ? <S.noOptions>No options</S.noOptions> :
            (!!checkbox ?
              <AVDropdownItemList
                checkbox
                options={options}
                initials={initials}
                values={state}
                unit={unit}
                config={config}
                isChecked={isChecked}
                onCheck={handleCheck}
                onEdit={handleEdit}
                isInvalid={isInvalid}
                validate={validate}
                onConfirm={() => {
                  setValues(getValues());
                  close();
                }}
              /> :
              <AVDropdownItemList
                options={options}
                unit={unit}
                config={config}
                onEdit={handleEdit}
                isInvalid={isInvalid}
                validate={validate}
                onConfirm={() => {
                  setValues(options);
                  close();
                }} />)
        }
      </Actions>
      {
        label && (
          <S.label hasValue={hasValue} open={open} {...rest}>
            {label}
          </S.label>
        )
      }
      {icon && <S.iconWrapper>{icon}</S.iconWrapper>}
      <S.items {...rest}>
        {values?.map((item: any, index: number) => {
          return (
            <Fragment key={item.id}>
              {item.name} ({item.value}{unit ? `\u00A0${unit}` : ''})
              {index < values.length - 1 && ',\u00A0'}
            </Fragment>
          );
        })}
      </S.items>
    </S.avWrapper >
  );
};

export default AssociatedValueDropdown;
