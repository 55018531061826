import tw, { styled, css } from 'twin.macro';
import { StyledProps as Props } from '../../types/util.types';
import authImage from '../../images/image_big-side.png';

export const wrapper = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { xl }
      }
    }
  }) => [
    css`
      ${tw`flex min-h-screen w-full flex-col xl:flex-row items-center pb-10 
      xl:pt-80 xl:pr-20 xl:pl-48 bg-no-repeat bg-left-top`}

      @media screen and (min-width: ${xl}px) {
        background-image: url(${authImage});
        background-size: 56.5rem 100%;
      }
    `
  ]
);

export const intro = styled.div<Props>(
  ({
    theme: {
      typography: { hero, lead },
      media: {
        breakpoints: { xsMax, xl }
      }
    }
  }) => [
    css`
      ${tw`flex flex-col items-center xl:items-start relative w-full pb-50 md:py-50 px-10 xl:p-100 lg:self-start `};
      color: #fff;

      * {
        position: relative;
        z-index: 5;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(30, 68, 92, 0.8);
      }

      h2 {
        ${hero}
        ${tw`text-40 md:text-56 lg:text-80 m-0 lg:mb-40 text-center lg:text-left leading-56 md:leading-80`}
      }

      p {
        ${lead}
        ${tw`text-25 lg:text-32 text-center lg:text-left`}
      }

      @media screen and (min-width: ${xl}px) {
        max-width: 63.5rem;
      }
    `
  ]
);

export const content = styled.div<Props>(
  ({
    theme: {
      media: {
        breakpoints: { xl }
      }
    }
  }) => [
    css`
      ${tw`flex items-center justify-center flex-grow lg:self-center lg:ml-30 `};

      @media screen and (min-width: ${xl}px) {
        margin-left: 22rem;
      }

      @media screen and (min-width: 1201px) and (max-width: ${xl}px) {
        margin-left: 10rem;
      }
    `
  ]
);

export const logo = styled.div(() => [
  css`
    ${tw`block mx-auto mt-60`}
  `
]);