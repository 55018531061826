import React, { useState, ChangeEvent } from 'react';
import { ReactSVG } from 'react-svg';
import useGlobal from '../../../store';
import {
  toggleUserStatus,
  changeRole,
  resendInvitation,
  deleteMember
} from '../../../store/members/members.actions';
import * as S from './members-item';
import Button from '../../../components/Button';
import CbRadio from '../../../components/Inputs/CbRadio';
import Actions from '../../../components/Actions';
import { Member } from '../../../store/members/members.types';
import UserRole from '../../../components/UserRole';
import ClientSuperAdminRoles from './ClientSuperAdminRoles';
import AdminRoles from './AdminRoles';
import MemberRoles from './MemberRoles';
import { roles } from '../../../utils/roles.utils';
import { toastUtil } from '../../../utils/toast.util';
import images from '../../../utils/images.utils';
import SimpleModal from '../../../components/Modal/SimpleModal/SimpleModal';
import { convertImageURI } from '../../../utils/images.utils';

interface Props extends Member {
  listView: boolean;
  onUpdate: () => Promise<any>;
  invitationEmailId: number;
}

const MembersListItem = ({
  id,
  firstName,
  lastName,
  email,
  image,
  role,
  status,
  listView,
  onUpdate,
  resendInvitationEmail,
  invitationEmailId,
}: Props) => {
  const [user] = useGlobal((state) => state.auth.currentUser);
  const [disabled, setDisabled] = useState<boolean>(status !== 'ACTIVE');
  const [newRole, setNewRole] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const toggle = () => setOpen(!open);

  const close = () => {
    if (open) setOpen(false);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleCheckChange = (evt: ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.name) {
      case 'role':
        setNewRole(evt.target.value)
        return;
      case 'disable':
        setDisabled(evt.target.checked)
        return;
      case 'delete':
        setIsDeleting(evt.target.checked)
        return;
    }
  }

  const updateUser = async () => {
    try {
      if (isDeleting) {
        toggleDeleteModal();
        close();
        setIsDeleting(false);
        return;
      }
      if ((status === 'ACTIVE' && disabled) || (status !== 'ACTIVE' && !disabled)) {
        await toggleUserStatus(id, disabled);
      }
      if (newRole !== '') {
        await changeRole(id, newRole);
      }
      await onUpdate();
      close();
      toastUtil('success', 'Success', '', 2000);
      setNewRole('');
    } catch (error) {
      console.warn(error);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    await deleteMember(id!);
    toggleDeleteModal();
    await onUpdate();
    setIsDeleting(false);
  }
  
  const resendInv = async () => {
    try {
      await resendInvitation(invitationEmailId);
      await onUpdate();
      close();
      toastUtil('success', 'Success', '', 2000);
    } catch (error) {
      console.warn(error);
    }
  };

  const disableMemberHandling = (memberRole: string) => {
    let isDisabled = false;
    if (user?.role === roles.admin && memberRole === roles.admin) {
      isDisabled = true;
    }
    return isDisabled
  }

  return (
    <>
      <S.wrapper listView={listView} disabled={status === 'INACTIVE'}>
        <S.header listView={listView} isClient={role === roles.client}>
          {image ? (
            <img src={convertImageURI(image)} alt={firstName} />
          ) : (
            <span>{`${lastName.substring(0, 2)}${firstName.substring(
              0,
              2
            )}`}</span>
          )}
        </S.header>
        <S.memberName listView={listView}>
          {firstName} {lastName}
        </S.memberName>
        <S.memberEmail listView={listView}>{email}</S.memberEmail>
        {resendInvitationEmail && (
          <S.noConfirmation listView={listView}>
            <>
              <ReactSVG src={images.papirPlaneIcon} />
              No confirmation
            </>
          </S.noConfirmation>
        )}
        <S.footer listView={listView}>
          <UserRole role={role} />
          {(role !== roles.superAdmin && user?.accountId !== id && 
          !disableMemberHandling(role)) && (
            <Actions
              listView={listView}
              toggle={toggle}
              open={open}
              close={close}
            >
              {(role === roles.client ||
                role === roles.superAdmin) &&
                <ClientSuperAdminRoles />
              }
              {role === roles.admin && (
                <AdminRoles
                  role={role}
                  handleRoleChange={handleCheckChange}
                  newRole={newRole}
                />
              )}
              {(role === roles.member) && (
                <MemberRoles
                  role={role}
                  handleRoleChange={handleCheckChange}
                  newRole={newRole}
                />
              )}
              <hr />
              <CbRadio
                name="disable"
                small
                onChange={handleCheckChange}
                checked={disabled}
                disabled={disableMemberHandling(role) || isDeleting}
              >
                Disable Member
              </CbRadio>
              {(user?.role === roles.superAdmin ||
                user?.role === roles.admin) &&
                <CbRadio
                  name="delete"
                  small
                  checked={isDeleting}
                  onChange={handleCheckChange}
                  disabled={disableMemberHandling(role) || disabled || status === 'INACTIVE'}
                >
                  Delete Member
                </CbRadio>
              }
              <Button secondary small onClick={updateUser}>
                Confirm
              </Button>
              {resendInvitationEmail && (
                <Button small onClick={resendInv}>
                  Resend invitation
                </Button>
              )}
            </Actions>
          )}
        </S.footer>
      </S.wrapper>

      <SimpleModal
        isOpen={showDeleteModal}
        onDismiss={toggleDeleteModal}
        confirm={onDelete}
        disabled={isDeleting}
      >
        <p>Are you sure you want to delete {firstName} {lastName}?</p>
        <p>
          Please be aware that by removing this member, he will be permanently
          deleted and removed from all projects.
        </p>
      </SimpleModal>
    </>
  );
};

export default MembersListItem;
