import React, { FunctionComponent, Fragment } from 'react';
import { AssociatedValueOption, AVDropdownConfig } from '../../../types/util.types';
import Button from '../../Button';
import AVDropdownItem from '../AVDropdownItem/AVDropdownItem';
import * as S from './associated-value-dropdown-item-list';

interface Props {
  options: AssociatedValueOption[],
  values?: (string | number)[],
  initials?: AssociatedValueOption[],
  checkbox?: boolean,
  unit?: string;
  numeric?: boolean,
  config?: AVDropdownConfig,
  isChecked?: (item: AssociatedValueOption) => boolean;
  onCheck?: (option: AssociatedValueOption, checked: boolean) => void;
  onEdit?: (option: AssociatedValueOption, value: string | number) => void;
  isInvalid: (option: AssociatedValueOption) => boolean,
  validate?: () => boolean;
  onConfirm?: () => void;
}

const AVDropdownItemList: FunctionComponent<Props> = ({
  options,
  values,
  initials,
  unit,
  checkbox = false,
  config,
  isChecked = () => false,
  onCheck = () => { },
  onEdit = () => { },
  isInvalid = () => false,
  validate = () => true,
  onConfirm = () => { }
}) => {

  return (
    <>
      <S.wrapper>
        {options?.map((item: any, index: number) => {
          return (
            <Fragment key={item.id}>
              <AVDropdownItem
                checkbox={checkbox}
                option={item}
                initial={!!initials ? initials[index] : undefined}
                config={config}
                checked={isChecked(item)}
                invalid={isInvalid(item)}
                unit={unit}
                onCheck={onCheck}
                onEdit={onEdit}
              >
                {item.name}
              </AVDropdownItem>
            </Fragment>
          );
        })}
      </S.wrapper>
      <Button secondary disabled={values?.length === 0 || !validate()} onClick={onConfirm}>
        Confirm
      </Button>
    </>
  );
};

export default AVDropdownItemList;
